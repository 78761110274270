import 'bootstrap/js/dist/util';
import { getCookie } from 'tiny-cookie';
let tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
if(tooltips.length) {
    import(/* webpackChunkName: "bs-tooltip" */ 'bootstrap/js/dist/tooltip')
    .then(() => {
        
        for(var i = 0; i < tooltips.length; i++)
        {
            console.log('loaded bs tooltips')
            let tooltip = tooltips.item(i);
            $(tooltip).tooltip();
        } 
    });
}

let modals = document.querySelectorAll('[data-toggle="modal"]');
if(modals.length) {
    import(/* webpackChunkName: "bs-modal" */ 'bootstrap/js/dist/modal')
    .then(() => {
        console.log('loaded bs modal');
        let message = getCookie("cccMessage");
        let wesgroNote = getCookie("wesgroNotice");
        if( $('[href="#myModalFAQ"]')){
            $('[href="#myModalFAQ"]').trigger('click');

        }
        if(!message || message != "false"){
            $('[href="#myModalNotice"]').trigger('click');
        }

        if( wesgroNote ){
            $('[href="#myModal"]').trigger('click');
        }

        let comp = getCookie("compEntered");
        if(!comp || comp != "false"){
            $('[href="#myModalCompetition"]').trigger('click');
        }
    });
}

let dropdown = document.querySelectorAll('[data-toggle="dropdown"]');
if(dropdown.length) {

    import(/* webpackChunkName: "bs-dropdown" */ 'bootstrap/js/dist/dropdown')
    .then(() => {
        console.log('loaded bs dropdown')
    })
}

let tabs = document.querySelectorAll('[data-toggle="tab"]');
if(tabs.length) {
    import(/* webpackChunkName: "bs-tab" */ 'bootstrap/js/dist/tab')
    .then(() => {
        console.log('loaded bs tabs')
    })
}

let alerts = document.querySelectorAll('[data-dismiss="alert"]');
if(alerts.length) {
    import(/* webpackChunkName: "bs-alert" */ 'bootstrap/js/dist/alert')
    .then(() => {
        console.log('alerts loaded');
    })
}

let collapses = document.querySelectorAll('.collapse');
if(collapses.length) {
    import(/* webpackChunkName: "bs-collapse" */ 'bootstrap/js/dist/collapse')
    .then(() => {
        console.log('collapse loaded');
    })
}

let toasts = document.querySelectorAll('.toast');
if(toasts.length) {
    import(/* webpackChunkName: "bs-toast" */ 'bootstrap/js/dist/toast')
    .then(() => {
        for(var i = 0; i < toasts.length; i++)
        {
            let toast = toasts.item(i);
            $(toast).toast('show');
        } 
    })
}

let menu = document.getElementById("menu");

if(menu) {
    import(/* webpackChunkName: "bs-scrollspy" */ 'bootstrap/js/dist/scrollspy')
    .then(() => {
        $('body').scrollspy({ target: '#menu',offset:100 });
    })
}

let selectpicker = document.querySelectorAll(".selectpicker");

if(selectpicker.length) {
    import(/* webpackChunkName: "bs-select" */ 'bootstrap-select')
    .then(() => {
        console.log('select picker loaded');
    })
}
 