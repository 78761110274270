import '../css/main.scss';
import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';


import "../js/bootstrap";
import "../js/fontawesome";

import UIBindings from "./ui-bindings";

import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";


(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();


let popups = document.querySelectorAll('[data-mfp-type]');
if(popups.length) {
    import(/* webpackChunkName: "magnific" */ '../js/magnificpopup')
        .then(() => {
            console.log('loaded magnific!');
            
        });
}

let shares = document.querySelectorAll('[data-share-default]');
if(shares.length) {
    import(/* webpackChunkName: "share" */ '../js/share')
        .then((ShareHandler) => {
            for (let i = 0; i < shares.length; i++) {
                const share = shares[i];
                (new ShareHandler.default(share)).initialize();
            }
        })
}
let slick = document.querySelectorAll('[data-slick]');
if(slick.length) {
    import(/* webpackChunkName: "share" */ '../js/slickcarousel')
        .then(() => {
            console.log('loaded slick!');
        })
}

let masonries = document.getElementsByClassName('isogrid');
if(masonries.length) {
    import(/* webpackChunkName: "isotope" */ './isotope')
        .then((Masonry) => {
            console.log('Masonry loaded')
            let masonry = new Masonry.default(masonries);
            masonry.initialize();
        });
}

let blogonries = document.getElementsByClassName('isogrid-2');
if(blogonries.length) {
    import(/* webpackChunkName: "isotope" */ './isotope')
        .then((Masonry) => {
            let masonry = new Masonry.default(blogonries);
            masonry.initialize();
        });
}

let forms = document.getElementsByClassName('needs-validation');
    if(forms.length) {
    
        import(/* webpackChunkName: "forms" */ '../js/form')
            .then((Forms) => {
                let form =  new Forms.default(forms);
                form.validate()
                form.steps();
                form.matrixField();

            })
    }


const UIBinding = new UIBindings();
UIBinding.formSubmitButton();

var offset = 80;

$('[data-uk-smooth-scroll]').click(function(event) {
    event.preventDefault();
    $($(this).attr('href'))[0].scrollIntoView();
    scrollBy(0, -offset);
});

$('[data-action-name]').click(function (){
    if($(this).attr('data-action-name') =="add-to-bucket-list"){
        var entryId = $(this).attr('data-action-value');
        var siteId = $(this).attr('data-site-id');

        var elemOne = document.getElementsByClassName("actionText-"+entryId);
        for (var i = 0; i < elemOne.length; i++) {
            elemOne[i].setAttribute('style', 'display:none !important');
        } 
        
        var elemTwo = document.getElementsByClassName("actionTextAdded-"+entryId);
        for (var i = 0; i < elemTwo.length; i++) {
            elemTwo[i].setAttribute('style', 'display:flex !important');
        }  

        if (localStorage.getItem("lists")) {
            var currentList = null;
            var storageLists =JSON.parse(localStorage.getItem("lists"));
            for (var i = 0; i < storageLists.length; i++) {
                if(storageLists[i].active == true){
                    currentList = storageLists[i];
                }
            }              
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    getCount()
                    var bucketlist = document.getElementById("bucketListDiv");
                    bucketlist.classList.add("active");
                    setTimeout(function(){ 
                        bucketlist.classList.remove("active");
                    }, 5000);

                }
            };
            xhttp.open("GET", "/actions/flow-wishlist/default/add-item?editId="+currentList.editId+"&types=entry&content="+entryId+"&siteId="+siteId, true);
            xhttp.send();

        } else{
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                const obj = JSON.parse(this.responseText);
                    var list = [{
                        editId:obj[0].edit_id,
                        uid:obj[0].uid,
                        name:obj[0].name,
                        active:true,
                        date: obj[0].dateCreated
                    }]
                    localStorage.setItem("lists", JSON.stringify(list));
                    var xhttp = new XMLHttpRequest();
                    xhttp.onreadystatechange = function() {
                        if (this.readyState == 4 && this.status == 200) {
                            var elemOne = document.getElementsByClassName("actionText-"+entryId);
                            for (var i = 0; i < elemOne.length; i++) {
                                elemOne[i].setAttribute('style', 'display:none !important');
                            } 
                            
                            var elemTwo = document.getElementsByClassName("actionTextAdded-"+entryId);
                            for (var i = 0; i < elemTwo.length; i++) {
                                elemTwo[i].setAttribute('style', 'display:flex !important');
                            }  
                            getCount()
                            var bucketlist = document.getElementById("bucketListDiv");
                            bucketlist.classList.add("active");
                            setTimeout(function(){ 
                                bucketlist.classList.remove("active");
                            }, 5000);
                        }
                    };
                    xhttp.open("GET", "/actions/flow-wishlist/default/add-item?editId="+obj[0].edit_id+"&types=entry&content="+entryId+"&siteId="+siteId, true);
                    xhttp.send();
                }
            };
            xhttp.open("GET", "/actions/flow-wishlist/default/create-list?name=Bucket List&order=0&parent=0", true);
            xhttp.send();
        }
    }
});



$( document ).ready(function() {
 

    if (window.location.href.indexOf("travel") > -1) {
        if (localStorage.getItem("lists")) {
            var storageLists =JSON.parse(localStorage.getItem("lists"));

            if(!"active" in storageLists[0]){
                localStorage.removeItem('lists');
            } else{
                var currentList = null;
                for (var i = 0; i < storageLists.length; i++) {
                    if(storageLists[i].active == true){
                        currentList = storageLists[i];
                    }
                }
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        if (this.responseText.match(/\{./)) {
                            load()
                        } else{
                            localStorage.removeItem('lists');
                            load()
                        }
                    }
                };
                xhttp.open("GET", "/actions/flow-wishlist/default/list?uid="+currentList.editId, true);
                xhttp.send();
            }
        }  else{
            load()
        }
    }
    
});


function load(){
    getCount();
    var actionTexts = document.getElementsByClassName("actionText");
    if(actionTexts){
        for (var i=0; i < actionTexts.length; i++) {
            if(actionTexts[i].getAttribute('data-action-name') == "add-to-bucket-list"){
                var entryId = actionTexts[i].getAttribute('data-action-value');
                var siteId = actionTexts[i].getAttribute('data-site-id');
                check(entryId,siteId);

            }
        }
    }
}
function check($entryId,$siteId){
    if (localStorage.getItem("lists")) {
        var currentList = null;
        var storageLists =JSON.parse(localStorage.getItem("lists"));
        for (var i = 0; i < storageLists.length; i++) {
            if(storageLists[i].active == true){
                currentList = storageLists[i];
            }
        }
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                if(this.responseText == 'true'){
                    var elemOne = document.getElementsByClassName("actionText-"+$entryId);
                    for (var x = 0; x < elemOne.length; x++) {
                        elemOne[x].setAttribute('style', 'display:none !important');
                    } 
                    
                    var elemTwo = document.getElementsByClassName("actionTextAdded-"+$entryId);
                    for (var i = 0; i < elemTwo.length; i++) {
                        elemTwo[i].setAttribute('style', 'display:flex !important');
                    }  
                }
            }
        };
        xhttp.open("GET", "/actions/flow-wishlist/default/item-check?editId="+currentList.editId+"&entryId="+$entryId+"&siteId="+$siteId, true);
        xhttp.send();
    } 
}

function getCount(){
    if (localStorage.getItem("lists")) {
        var currentList = null;
        var storageLists =JSON.parse(localStorage.getItem("lists"));
        for (var i = 0; i < storageLists.length; i++) {
            if(storageLists[i].active == true){
                currentList = storageLists[i];
            }
        }
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                if(this.responseText == "No List"){
                    document.getElementById("bucketlisttext").innerHTML = "Dream up your One Day bucket list here"
                } else{
                    if(document.getElementById("bucketListCount")){
                        document.getElementById("bucketListCount").innerHTML =this.responseText;
                    }
                    if(this.responseText > 0){
                        if(document.getElementById("bucketlisttext")){
                            document.getElementById("bucketlisttext").innerHTML = "View your exciting bucket list";
                        }
                    } else{
                        if(document.getElementById("bucketlisttext")){
                            document.getElementById("bucketlisttext").innerHTML = "Dream up your One Day bucket list here";
                        }
                    }
                }
            }
        };
        xhttp.open("GET", "/actions/flow-wishlist/default/item-count?editId="+currentList.editId, true);
        xhttp.send();

    }  else{
        document.getElementById("bucketlisttext").innerHTML = "Dream up your One Day bucket list here";
    }
}


if (window.location.href.indexOf("travel") > -1) {

    if(document.getElementById('bucketListDivClick')){
        document.getElementById('bucketListDivClick').onclick = function(e) {

            if (localStorage.getItem("lists")) {
                var currentList = null;
                var storageLists =JSON.parse(localStorage.getItem("lists"));
                for (var i = 0; i < storageLists.length; i++) {
                    if(storageLists[i].active == true){
                        currentList = storageLists[i];
                    }
                }            
                if(currentList.editId){

                    var xhttp = new XMLHttpRequest();
                    xhttp.onreadystatechange = function() {
                        if (this.readyState == 4 && this.status == 200) {
                            document.getElementById("bucketListCount").innerHTML =this.responseText;
                            if(this.responseText > 0){
                                window.location.href = '/travel/wishlist?editId='+currentList.editId;
                            } else{
                                window.location.href = '/travel/attractions';
                            }
                        }
                    };
                    xhttp.open("GET", "/actions/flow-wishlist/default/item-count?editId="+currentList.editId, true);
                    xhttp.send();
                }
            } else{
                window.location.href = '/travel/attractions';

            }
        }

        document.getElementById('closeWishlist').onclick = function(e) {
            document.getElementById('bucketListDiv').setAttribute('style', 'display:none !important');
        }
    }
}


